import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {InputBase, Stack} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { debounce } from "lodash";

import { Link } from "../../../../../helpers/multilingual";
import { SnackbarConfirm } from "../../../../../helpers/snackbar";
import { getFormalizedName } from "../../helpers/helper";
import { shopActions } from "../../store/actions";
import ItemPrice from "../ItemPrice";

const CartItem = ({item}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const debouncedDispatch = debounce((item, quantity) => dispatch(shopActions.setCartItemQuantity(item, quantity)), 180);
  const isUpMx = useMediaQuery(theme => theme.breakpoints.up('mw'));

  useEffect(() => {
    if(!item.price) {
      dispatch(shopActions.setCartItemQuantity(item, 0));
    }
  }, []);

  const handleChangeItemQuantity = (item, quantity) => {

    if(quantity === 0) {
      SnackbarConfirm(t("removeItemFromCart"), () => {
        debouncedDispatch(item, quantity);
      });
    }
    else {
      debouncedDispatch(item, quantity);
    }
  };

  return (
    <ListItem sx={{
      borderRadius: '12px',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      }
    }}>
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item xs={2} sm={2}>
          <Grid container justify={"center"} alignItems={"center"}>
            <img
              width={60}
              height={60}
              src={item?.image?.[200]}
              alt={item.reference}
            />
          </Grid>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Grid container justify={"center"} alignItems={"center"}>
            <Grid item xs={10} sx={{pl: '0px !important'}}>
              <Typography
                component={Link} to={`/shop/product/${getFormalizedName(item.reference)}/${item.id}`}
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  color: '#7C909B',
                }}>
                {item.reference}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {item && item.discountPercent > 0 && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '0.8rem',
                    fontFamily: 'Montserrat',
                    color: 'rgb(34, 154, 22)',
                    backgroundColor: 'rgba(84, 214, 44, 0.16)',
                  }}
                >
                  {`- ${item.discountPercent}%`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={2}>
            <Grid item sx={{pl: '12px !important'}}>
              <Paper
                component="form"
                sx={{
                  boxShadow: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#7C909B1A',
                  height: '24px',
                  borderRadius: '12px',
                  width: '82px'
                }}>
                <IconButton
                  fontSize="small"
                  sx={{ p: '2px', color: '#BDC7CD' }}
                  aria-label="minus"
                  onClick={() => handleChangeItemQuantity(item, item.quantity - 1)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                <InputBase
                  name={"quantity"}
                  value={item.quantity}
                  sx={{
                    ml: 0,
                    flex: 1,
                    color: '#7C909B',
                    fontSize: '16px',
                    fontFamily: 'Open sans',
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                    }
                  }}/>
                <IconButton
                  type="button"
                  sx={{ p: '2px', color: '#7C909B' }}
                  aria-label="plus"
                  onClick={() => handleChangeItemQuantity(item, item.quantity + 1)}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item sx={{pl: '0 !important'}}>
              <Stack direction={"row"} alignItems={"center"} alignContent={"center"} gap={1}>
                <Typography sx={{
                  '& span': {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat',
                    color: '#7C909B'
                  }
                }}>
                  <ItemPrice
                    item={item}
                    component={"span"}
                    quantity={item.quantity}
                    total
                  />
                </Typography>
                {isUpMx && (
                  <IconButton
                    onClick={() => handleChangeItemQuantity(item, 0)}
                    sx={{
                      color: '#7C909B',
                      background: '#ffffff',
                      boxShadow: '12px 12px 24px #00000021',
                      padding: '4px'
                    }}>
                    <DeleteOutlineOutlinedIcon/>
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};
export default CartItem;
