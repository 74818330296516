import {createSelector} from "reselect";

import { hasTaxes } from "../../../../helpers/utils";
import { getUserShippingAddressSelector,userSelector } from "../../../Authentication/store/selectors";

import {SHIPPING_FREE_THRESHOLD_AMOUNT, TAXES_FULL, TAXES_NONE, TAXES_REDUCED, TAXES_SUBJECT_TO} from "./constants";
import { initialState } from "./initialState";
import {shopReducerKey} from "./reducer";

export const shopSelector = globalState => globalState[shopReducerKey] || initialState.shop;

export const userShippingSelector = createSelector(
  [ shopSelector ],
  (shop) => shop && shop.userShipping ? shop.userShipping : {});
export const userShippingAddressesSelector = createSelector(
  [ userShippingSelector ],
  (shipping) => {
    if(!shipping.addresses) return shipping.addresses;
    let addresses = shipping.addresses.slice();

    return addresses.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
  });
export const userShippingAddressesLoaderSelector = createSelector(
  [ userShippingSelector ],
  (shipping) => shipping.addressesRequested);
export const addShippingAddressLoaderSelector = createSelector(
  [ userShippingSelector ],
  (shipping) => shipping.addAddressRequested);

export const shippingAddressSelectedSelector = createSelector(
  [shopSelector, getUserShippingAddressSelector],
  (shop, userShippingAddress) => {
    if(shop.cart.shipping.address) {
      return shop.cart.shipping.address;
    }
    return userShippingAddress;
  }
);

export const shippingPickupPointSelectedSelector = createSelector(
  [shopSelector],
  (shop) => {
    if(shop.cart.shipping.pickupPoint) {
      return {
        id: shop.cart.shipping.pickupPoint.id,
        name: shop.cart.shipping.pickupPoint.name,
        type: shop.cart.shipping.pickupPoint.type,
        street: shop.cart.shipping.pickupPoint.street,
        number: shop.cart.shipping.pickupPoint.number,
        zip: shop.cart.shipping.pickupPoint.zip,
        city: shop.cart.shipping.pickupPoint.city,
        country: shop.cart.shipping.pickupPoint.country,
      };
    }
    return null;
  }
);

export const shippingMethodsSelector = createSelector(
  [shopSelector],
  (shop) => shop.cart.shipping.methods
);

export const bpostShippingMethodsSelector = createSelector(
  [shopSelector],
  (shop) => shop.cart.shipping.methods.filter(method => method.carrier === 'bpost')
);

export const dhlShippingMethodsSelector = createSelector(
  [shopSelector, shippingAddressSelectedSelector],
  (shop, shippingAddress) => {
    if((shippingAddress && !shippingAddress.latitude) || (shippingAddress && !shippingAddress.longitude)) {
      return shop.cart.shipping.methods.filter(address => address.pickupPoint === 0 && address.carrier === 'DHL Express');
    }
    return shop.cart.shipping.methods.filter(method => method.carrier === 'DHL Express');
  }
);

export const fedexStandardShippingMethodsSelector = createSelector(
  [shopSelector, shippingAddressSelectedSelector],
  (shop, shippingAddress) => {
    if((shippingAddress && !shippingAddress.latitude) || (shippingAddress && !shippingAddress.longitude)) {
      return shop.cart.shipping.methods.filter(address => address.pickupPoint === 0 && address.carrier === 'FedEx');
    }
    return shop.cart.shipping.methods.filter(method => method.carrier === 'FedEx - Standard');
  }
);

export const fedexPriorityShippingMethodsSelector = createSelector(
  [shopSelector, shippingAddressSelectedSelector],
  (shop, shippingAddress) => {
    if((shippingAddress && !shippingAddress.latitude) || (shippingAddress && !shippingAddress.longitude)) {
      return shop.cart.shipping.methods.filter(address => address.pickupPoint === 0 && address.carrier === 'UPS');
    }
    return shop.cart.shipping.methods.filter(method => method.carrier === 'FedEx - Priority');
  }
);

export const useWalletSelector = createSelector(
  [shopSelector],
  (shop) => !!shop.cart.useWallet
);

export const promotionCodeSelector = createSelector(
  [shopSelector],
  (shop) => shop.promotionCode
);

export const isCheckoutOpenSelector = createSelector(
  [shopSelector],
  (shop) => shop.drawers.checkout
);

export const isReadOnlyCheckoutOpenSelector = createSelector(
  [shopSelector],
  (shop) => shop.drawers.readOnlyCheckout
);

export const isResponseCheckoutOpenSelector = createSelector(
  [shopSelector],
  (shop) => shop.drawers.response
);

export const isAccountOpenSelector = createSelector(
  [shopSelector],
  (shop) => shop.drawers.account
);

export const shippingMethodSelectedSelector = createSelector(
  [shopSelector],
  (shop) => shop.cart.shipping.method
);

export const multisafepayPaymentMethodSelectedSelector = createSelector(
  [shopSelector],
  (shop) => shop.cart.shipping.method
);

export const sessionSelector = createSelector(
  [shopSelector, userSelector, shippingAddressSelectedSelector],
  (shop, user, shippingAddress) => {
    if(user) {
      const country = shippingAddress ? shippingAddress.country : 'LU';
      return {
        country: country,
        currency: 'EUR',
        shippingAddress: shippingAddress,
        //currency: shippingAddress ? shippingAddress?.currency : 'EUR',
        hasTaxes: hasTaxes(user, country),
        isPro: user.isPro,
        role: user.role,
        allowFreeShipping: user.allowFreeShipping,
        consultant: user.consultant,
        registered: true,
        isCountrySupported: !!shippingAddress?.VAT,
        VAT: {
          [TAXES_FULL]: user.shippingAddress?.VAT?.full,
          [TAXES_REDUCED]: user.shippingAddress?.VAT?.reduced,
          [TAXES_SUBJECT_TO]: user.shippingAddress?.VAT?.subjectTo,
          [TAXES_NONE]: 0
        },
        discountMultiplier: user?.userDiscount?.percentage ? 1 - (user.userDiscount.percentage / 100) : 1,
        userDiscountId: user.userDiscountId,
        userDiscount: user.userDiscount,
        favorites: user.favorites,
      };
    }

    if(shop.session) {
      return {...shop.session, isPro: false, registered: false};
    }

    return {
      country: null,
      currency: 'EUR',
      //currency: shippingAddress ? shippingAddress?.currency : 'EUR',
      hasTaxes: false,
      isPro: false,
      role: null,
      allowFreeShipping: false,
      registered: false,
      isCountrySupported: false,
      VAT: {
        [TAXES_FULL]: null,
        [TAXES_REDUCED]: null,
        [TAXES_SUBJECT_TO]: null,
        [TAXES_NONE]: 0
      },
      discountMultiplier: null,
      userDiscountId: null,
      userDiscount: null,
      favorites: null,
    };
  }
);

export const sessionErrorSelector = createSelector(
  [shopSelector],
  (shop) => shop.sessionError
);

export const shopProductsLoaderSelector = createSelector(
  [shopSelector],
  shop => shop.productsRequested
);

export const shopProductsSelector = (keyType, section, sectionId, country, lang) => createSelector(
  [shopSelector],
  shop => shop.products[`${keyType}-${section}-${sectionId}-${country}-${lang}`]?.data
);

export const shopTopProductsSelector = createSelector(
  [shopSelector],
  shop => shop.topProducts
);

export const endOfProductsSelector = (keyType, section, sectionId, country, lang) => createSelector(
  [shopSelector],
  shop => shop.products[`${keyType}-${section}-${sectionId}-${country}-${lang}`]?.endOfProducts
);
export const currentPageSelector = (keyType, section, sectionId, country, lang) => createSelector(
  [shopSelector],
  shop => shop.products[`${keyType}-${section}-${sectionId}-${country}-${lang}`]?.currentPage || 0
);

export const shopCatalogProductsSelector = createSelector(
  [shopSelector],
  shop => shop.catalog
);

export const searchShopProductsSelector = createSelector(
  [shopSelector],
  shop => shop.searchProducts
);

export const shopSearchSelector = createSelector(
  [shopSelector],
  shop => shop.search
);

export const isShopSearchDrawerOpenSelector = createSelector(
  [shopSelector],
  shop => shop.isSearchDrawerOpen
);

export const shopCategoriesSelector = createSelector(
  [shopSelector],
  shop => shop.categories
);

export const shopThemesSelector = createSelector(
  [shopSelector],
  shop => shop.themes
);

export const shopBrandsSelector = createSelector(
  [shopSelector],
  shop => shop.brands
);

export const shopCategorySelector = lang => createSelector(
  [shopSelector],
  shop => shop.categories[lang]?.find(category => shop.categoryId === category.id)
);

export const shopProductSelector = createSelector(
  [shopSelector],
  shop => shop.product
);

export const cartSelector = createSelector(
  [shopSelector],
  shop => shop.cart
);

export const itemsFromCartSelector = createSelector(
  [cartSelector],
  cart => cart.items
);

export const countItemsFromCartSelector = createSelector(
  [itemsFromCartSelector],
  items => {
    let count = 0;
    items && Object.keys(items).map(key => {
      count += items[key].quantity;
    });
    return count;
  }
);

export const cartDetailsSelector = createSelector(
  [itemsFromCartSelector, sessionSelector],
  (items, session) => {

    let discount = 0;
    let subTotal = 0;
    let taxes = {};
    let total = 0;
    if(!session) return {
      discount,
      subTotal,
      taxes,
      total
    };

    let currency = session?.currency ? session.currency : "eur";
    Object.keys(items).map(key => {
      const discountMultiplier = session?.discountMultiplier ? session.discountMultiplier : 1;
      const taxesRate = items[key].type === 'event' ? items[key].productVAT.full / 100 : session?.VAT[items[key].taxesType] / 100;
      let itemPrice = items[key].quantity * items[key].price;
      let discountPrice = itemPrice * (1 - discountMultiplier);
      let itemTaxes = session.hasTaxes || items[key].taxesMandatory ? (itemPrice - discountPrice) * taxesRate : 0;
      discount += discountPrice;
      subTotal += itemPrice - discountPrice;
      taxes[taxesRate * 100] = taxes[taxesRate * 100] ? taxes[taxesRate * 100] + itemTaxes : itemTaxes;
      total += itemPrice - discountPrice + itemTaxes;
    });
    const isFreeShipping = false;
    Object.keys(taxes).map(taxRate => {
      taxes[taxRate] = Math.round(taxes[taxRate] * 100) / 100;
    });
    return {items, shippingPrice: 0, shippingTaxes: 0, discount, subTotal, taxes, total, currency, isFreeShipping, allowFreeShipping: session.allowFreeShipping};
  }
);

export const checkoutSelector = createSelector(
  [shopSelector],
  shop => shop.checkout ? shop.checkout : null
);

export const processingCheckoutSelector = createSelector(
  [shopSelector],
  shop => shop.isCheckoutProcessing
);

export const elementFormReadySelector = createSelector(
  [shopSelector],
  shop => shop.elementFormReady
);

export const paymentMethodSelector = createSelector(
  [shopSelector],
  shop => shop.paymentMethod
);

export const itemsFromCheckoutSelector = createSelector(
  [checkoutSelector],
  (checkout) => {
    let items = {};
    if(checkout && checkout.order) {
      checkout.order.products.map(item => {
        items[item.productId] = {
          ...item.originalProduct,
          currency: item.currency,
          quantity: item.quantity,
          multiplier: checkout.order.isProOrder ? 1 - item.directSaleMultiplier : 1,
          discountPercent: item.discountPercent
        };
      });
    }
    return items;
  }
);

export const giftedItemsFromCheckoutSelector = createSelector(
  [checkoutSelector],
  (checkout) => {
    let items = {};
    if(checkout && checkout.order) {
      checkout.order.giftedProducts.map(item => {
        items[item.id] = {
          originalProduct: {...item},
          quantity: item.quantity
        };
      });
    }
    return items;
  }
);

export const checkoutDetailsSelector = createSelector(
  [checkoutSelector],
  checkout => {
    if(!checkout || !checkout.order) {
      return null;
    }
    return {
      ...checkout.order,
      allowFreeShipping: checkout.order.allowFreeShipping,
      isFreeShipping: checkout.order.shippingPrice === 0,
      subTotal: checkout.order.totalPrice,
      taxes: checkout.order.taxesDetails,
      total: checkout.order.totalPrice + checkout.order.taxes,
    };
  }
);

export const itemsFromCartOrCheckoutSelector = createSelector(
  [itemsFromCartSelector, itemsFromCheckoutSelector],
  (itemFromCart, itemFromCheckout) => itemFromCheckout ? itemFromCheckout : itemFromCart
);

export const weightFromCartOrCheckoutSelector = createSelector(
  [itemsFromCartOrCheckoutSelector],
  (items) => {
    let weight = 0;
    Object.keys(items).map(itemId => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight;
  }
);

export const needDeliverySelector = createSelector(
  [itemsFromCartSelector, itemsFromCheckoutSelector],
  (itemsFromCart, itemsFromCheckout) => {
    const items = Object.keys(itemsFromCheckout) > 0 ? itemsFromCheckout : itemsFromCart;
    let weight = 0;
    Object.keys(items).map(itemId => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight > 0;
  }
);

export const weightFromCheckoutSelector = createSelector(
  [itemsFromCheckoutSelector],
  (items) => {
    let weight = 0;
    Object.keys(items).map(itemId => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight;
  }
);

export const weightFromCartSelector = createSelector(
  [itemsFromCartSelector],
  (items) => {
    let weight = 0;
    Object.keys(items).map(itemId => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight;
  }
);

export const cartOrCheckoutDetailsSelector = createSelector(
  [cartDetailsSelector, checkoutDetailsSelector],
  (cartDetails, checkoutDetails) => checkoutDetails ? checkoutDetails : cartDetails
);

export const countShippingFreeThresholdLeftSelector = createSelector(
  [cartOrCheckoutDetailsSelector],
  ({shippingPrice, shippingTaxes, total, currency, allowFreeShipping}) => ({
    currency,
    allowFreeShipping,
    amountLeft: SHIPPING_FREE_THRESHOLD_AMOUNT[currency] - total + shippingPrice + shippingTaxes,
  })
);

export const isFreeShippingSelector = createSelector(
  [countShippingFreeThresholdLeftSelector],
  ({amountLeft, allowFreeShipping}) => allowFreeShipping && amountLeft <= 0
);

export const ordersSelector = createSelector(
  [shopSelector],
  (shop) => shop.orders
);

export const isCartModalOpenedSelector = createSelector(
  [cartSelector],
  cart => cart.isModalOpened
);

export const giftCardsSelector = createSelector(
  [shopSelector],
  shop => shop.giftCards || []
);

export const giftCardSelector = createSelector(
  [shopSelector],
  shop => shop.giftCard
);

export const activeCampaignSelector = createSelector(
  [shopSelector],
  shop => shop.activeCampaign
);

export const favoritesAddedSelector = createSelector(
  [shopSelector],
  shop => shop.favoritesAdded
);

export const favoritesRemovedSelector = createSelector(
  [shopSelector],
  shop => shop.favoritesRemoved
);

export const shopProductRequestAnsweredEmptySelector = createSelector(
  [shopSelector],
  shop => shop.shopProductRequestAnsweredEmpty
);

export const fromInternalNavigationSelector = createSelector(
  [shopSelector],
  shop => shop.fromInternalNavigation
);

export const isProductFavoriteSelector = (productId, isFavorite) => createSelector(
  [favoritesAddedSelector, favoritesRemovedSelector],
  (favoritesAdded, favoritesRemoved) => {
    if(favoritesRemoved?.some(id => id === productId)) {
      return false;
    }
    if (isFavorite && !favoritesRemoved?.some(id => id === productId)) {
      return true;
    } else return !isFavorite && favoritesAdded?.some(id => id === productId);
  }
);
